import React from "react";
import { Marker } from "react-google-maps";
/* cspell:disable-next-line */
import memoize from "memoizee";
import { PostMarkerData } from "../../types/EventMap";
import { encodeSvg } from "./getEncodedIconByType";
import { color } from "../../styles/colors";
import { useAuth } from "../../contexts/AuthProvider";
import { kpToMp } from "../AnalysisCharts/BaseChart";

type PropsType = {
  posts: Array<PostMarkerData>;
  zoomLevel: number;
};

type IdlerPosts = {
  [dictKey: number]: number;
};

/* eslint-disable no-magic-numbers */
const idlerPostsMain: IdlerPosts = {
  0.0: 25,
  0.055: 53,
  0.101: 85,
  0.15: 118,
  0.198: 150,
  0.253: 186,
  0.296: 216,
  0.345: 247,
  0.397: 282,
  0.441: 312,
  0.488: 343
};
/* eslint-enable */

const FONT_SIZE = 12;
const ICON_PADDING = 10;
const CHARACTER_WIDTH_PX = 7;

const half = (num: number) => num * 0.5; // eslint-disable-line no-magic-numbers

const roundToThousandth = (num: number) => {
  const numberOfFixedDigits = 3;
  return parseFloat(num.toFixed(numberOfFixedDigits));
};

/* eslint-disable no-magic-numbers */
const getMinimumPostSpacing = (
  zoomLevel: number
): { minimumPostSpacing: number; snapToMarker: number } => {
  // Spacing assumes kilometers(KM) are used as the
  // distance unit for posts. If different units are introduced,
  // consider augmenting the spacing accordingly.
  let minimumPostSpacing = 3;
  let snapToMarker = 1;

  if (zoomLevel > 10) {
    minimumPostSpacing = 3;
  }
  if (zoomLevel > 12) {
    minimumPostSpacing = 2;
  }
  if (zoomLevel > 14) {
    minimumPostSpacing = 1;
  }
  if (zoomLevel > 15) {
    minimumPostSpacing = 0;
    snapToMarker = 0.2;
  }

  return { minimumPostSpacing, snapToMarker };
};

// We want to render the post markers on the map as SVGs, but Google Maps doesn't allow that, so we
// have to render out the component using react-dom/server then encode them as images for Google
// maps to render them. This has the side-effect of forcing us having to hard-code the text styles
const generatePostIcon = memoize(function generatePostIcon(
  post: PostMarkerData,
  imperialFlag: boolean
) {
  const postValue = post.post;
  const postFormated = postValue.toFixed(1).toString();
  const height = 2 * ICON_PADDING + FONT_SIZE;
  const width = 2 * ICON_PADDING + postFormated.length * CHARACTER_WIDTH_PX;

  const teckFlag = post.pipelineId === "teck_gho";
  console.log("TECKFlag:", teckFlag);
  let teckPostFormatted = "";
  if (postValue in idlerPostsMain) {
    console.log("TECKPostToShowFirst:", idlerPostsMain[postValue]);
    teckPostFormatted = idlerPostsMain[postValue].toString();
    console.log("TECKPostToShow:", teckPostFormatted);
  }

  const encodedIcon = encodeSvg(
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${height}px ${width}px`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        stroke={color.gray}
        strokeWidth="2"
        fill={color.darkMineShaft}
        width={`${width}px`}
        height={`${height}px`}
        opacity="0.75"
        vectorEffect="none-scaling-stroke"
      />
      <text
        stroke={color.white}
        fill={color.white}
        strokeWidth="0.5"
        fontSize={`${FONT_SIZE}px`}
        style={{
          fontFamily: `Inter var, sans-serif`,
          fontFeatureSettings: `"ss02" 1`,
          letterSpacing: `1.1px`
        }}
        fontWeight="300"
        textAnchor="middle"
        x={half(width)}
        y={half(height) + half(FONT_SIZE)}
      >
        {teckFlag
          ? teckPostFormatted
          : imperialFlag
          ? kpToMp(postValue, 1)
          : postFormated}
      </text>
    </svg>
  );
  return {
    encodedIcon,
    height,
    width
  };
});
/* eslint-enable */

const PostMarkers = ({ posts, zoomLevel }: PropsType) => {
  const { minimumPostSpacing, snapToMarker } = getMinimumPostSpacing(zoomLevel);

  const lastPostByPipeline: {
    [pipelineId: string]: number;
  } = {};
  const visiblePosts: Array<PostMarkerData> = [];

  posts.forEach(post => {
    const postValue: number = post.post;

    if (lastPostByPipeline[post.pipelineId] === undefined) {
      //Ensures first snapToMarker Value is used on a pipeline
      lastPostByPipeline[post.pipelineId] = -minimumPostSpacing;
    }

    const distanceToLastVisiblePost = roundToThousandth(
      postValue - lastPostByPipeline[post.pipelineId]
    );
    const distanceToSnapToMarker = roundToThousandth(postValue / snapToMarker);

    if (
      distanceToLastVisiblePost >= minimumPostSpacing &&
      distanceToSnapToMarker % 1 === 0 &&
      post.pipelineId !== "teck_gho"
    ) {
      lastPostByPipeline[post.pipelineId] = postValue;
      visiblePosts.push(post);
    }

    if (post.pipelineId === "teck_gho") {
      console.log("postValue:", postValue);
      const checkVal = postValue in idlerPostsMain;
      console.log("Logic Value:", checkVal);
      if (postValue in idlerPostsMain) {
        console.log("Adding idler number post.post to visible posts");
        console.log("post.post:", post.post);
        visiblePosts.push(post);
        console.log("Visible posts:", visiblePosts);
      }
    }
  });

  const { permissions } = useAuth();

  return (
    <>
      {visiblePosts &&
        visiblePosts.map(post => {
          const { encodedIcon, height, width } = generatePostIcon(
            post,
            (permissions.user.imperial ? true : false) ||
              post.pipelineId === "enbridge_line_3" ||
              post.pipelineId === "enbridge_line_4"
          );
          const icon = {
            url: encodedIcon,
            anchor: new window.google.maps.Point(half(width), half(height))
          };

          return (
            <Marker
              key={post.id}
              position={{
                lat: post.coordinates.latitude,
                lng: post.coordinates.longitude
              }}
              icon={icon}
              zIndex={10}
            />
          );
        })}
    </>
  );
};

export default React.memo(PostMarkers);
